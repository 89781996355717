import React, { Fragment, useContext, useImperativeHandle, forwardRef } from 'react';
import { LayoutContext } from '../../../Layout';

export const CancelTradeOffer = forwardRef((props, ref) => {

    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return false;
        },
        onSave() {
            onSaveFunction();
        }
    }));

    const onSaveFunction = async () => {

        props.setIsLoading(true);

        api.doGET(`bot/cancel-trade?id=${props.id}`).then(result => {
            if (result.success) {
                notifyToast({
                    title: 'Troca cancelada com sucesso',
                    static: false,
                    variant: 'success'
                });

                props.toggle();
            } else {
                notifyToast({
                    title: 'Erro ao solicitar cancelamento da troca',
                    static: false,
                    variant: 'error'
                });
            }

            props.setIsLoading(false);
            props.callBack();
        });

    };

    return (<Fragment>
        <div className='slds-text-color_default'>
            Deseja realmente cancelar a troca?
        </div>
    </Fragment>);
});