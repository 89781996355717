import React, { useRef, Fragment, useState, useEffect, useContext } from 'react';
import {
    GlobalHeader, GlobalHeaderProfile, GlobalHeaderSetup, Dropdown, GlobalNavigationBarDropdown,
    IconSettings, MediaObject, GlobalNavigationBar, GlobalNavigationBarRegion, GlobalNavigationBarLink, Popover,
    VerticalNavigation, AppLauncher
} from '@salesforce/design-system-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import BrSkinsLogo from '../customstyles/logo/br-skins-logo-black.png';
import { NavItem, NavLink} from 'reactstrap';
import { CustomAction } from './Administration/actions/CustomAction';
import { LayoutContext } from './Layout';
import eventManager from './common/ExtensionLibrary';

export const NavMenu = () => {
    const [object, setObject] = useState();
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(false);
    const customActionModal = useRef(null);
    const { matches } = useContext(LayoutContext);

    useEffect(() => {
        authService.isAuthenticated().then((isAuth) => {
            setAuthenticated(isAuth);
        });

        authService.getUser().then((user) => {
            setUser(user);
        });

        const changeHelmetData = (objectParam) => { setObject(objectParam); }
        eventManager.subscribe("SetCurrentObject", changeHelmetData)
    }, []);

    const dropdownMercado = [
        {
            id: 'subMenuAnuncio',
            label: 'Anúncios',
            value: '1',
            iconCategory: 'utility',
            iconName: 'table',
            href: '/Offer/list',
        },
        {
            id: 'subMenuSolicitacaoRetirada',
            label: 'Solicitação de retirada',
            value: '2',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/WithdrawRequest/list',
        },
        {
            id: 'subMenuPagamento',
            label: 'Pagamentos',
            value: '4',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/PaymentRequest/list',
        },
        {
            id: 'subMenuTransacao',
            label: 'Transações',
            value: '5',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/Transaction/list',
        },
        {
            id: 'subMenuTransacao',
            label: 'Transferir asset',
            value: '6',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/AssetTransfer/list',
        },
        {
            id: 'subMenuCupom',
            label: 'Cupons',
            value: '3',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/Coupon/list',
        }
    ];

    const dropdownFAQ = [
        {
            id: 'subMenuCategoria',
            label: 'Categoria',
            value: '1',
            iconCategory: 'utility',
            iconName: 'table',
            href: '/FAQCategory/list',
        },
        {
            id: 'subMenuArtigo',
            label: 'Artigo',
            value: '2',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/FAQArticle/list',
        }
    ];

    const dropdownProdutos = [
        {
            id: 'subMenuProduto',
            label: 'Produtos',
            value: '1',
            href: '/Product/list'
        },
        {
            id: 'subMenuTabelaPreco',
            label: 'Tabela de preços',
            value: '2',
            href: '/Pricebook/list',
        },
        {
            id: 'subMenuProdutoCategoria',
            label: 'Categorias',
            value: '3',
            href: '/ProductCategory/list',
        },
        {
            id: 'subMenuProductSubCategoria',
            label: 'Sub-Categorias',
            value: '4',
            href: '/ProductSubCategory/list',
        },
        {
            id: 'subMenuProductGroup',
            label: 'Grupo',
            value: '5',
            href: '/ProductGroup/list',
        }
    ];

    const dropdownGiveaway = [
        {
            id: 'subGiveaway',
            label: 'Sorteios',
            value: '1',
            href: '/Giveaway/list'
        },
        {
            id: 'subGiveawayEntry',
            label: 'Entrada dos sorteios',
            value: '2',
            href: '/GiveawayEntry/list',
        },
        {
            id: 'subChallenge',
            label: 'Desafios',
            value: '3',
            href: '/Challenge/list',
        },
        {
            id: 'subChallengeAnswer',
            label: 'Respostas dos desafios',
            value: '4',
            href: '/ChallengeAnswer/list',
        }
    ];

    const parametrosMercado = [
        {
            label: 'Parâmetros globais',
            value: '1',
            iconCategory: 'utility',
            iconName: 'table',
            href: '/GlobalParams/list',
        },
        {
            label: 'Taxa do produto',
            value: '2',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/ProductMarketFee/list',
        },
        {
            label: 'Bots',
            value: '3',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: '/BotConfiguration/list',
        }
    ];

    const logoutPath = `${ApplicationPaths.LogOutCallback}`
    const logoutState = { local: true };

    const userAvatarMenu = (<Fragment>
        <NavItem>
            <NavLink replace tag={Link} className="text-dark" to={logoutPath} state={logoutState}>Logout</NavLink>
        </NavItem >
    </Fragment >);

    const selectGlobalItem = (selectedItem) => {
        switch (selectedItem.id) {
            case "EditarLayout":
                setConfiguration();
                break;
            case "Configuracao":
                window.location.replace('/configuration')
                break;
            default:
                break;
        }
    }

    const setConfiguration = () => {

        customActionModal.current.setTitle(`Configuração de layout - ${object}`);
        customActionModal.current.setTarget('', object, { componentName: 'LayoutConfiguration'});
        customActionModal.current.toggle();
    }

    const onRefresh = async () => {
        customActionModal.current.toggle();
        window.location.reload();
    }

    let globalHeaderOptions = [
        {
            id: 'Configuracao', label: 'Configurações'
        }
    ];

    if (object) {
        globalHeaderOptions.push({
            id: 'EditarLayout', label: 'Editar Layout de página'
        });
    }

    const menuParams = {
        dropdownMercado:dropdownMercado,
        dropdownProdutos:dropdownProdutos,
        parametrosMercado: parametrosMercado,
        dropdownFAQ: dropdownFAQ,
        dropdownGiveaway: dropdownGiveaway
    }

    return (<Fragment>
        {isAuthenticated && (
            <IconSettings iconPath="/assets/icons">
                <CustomAction ref={customActionModal} onSuccess={() => onRefresh()} />
                <GlobalHeader logoSrc={BrSkinsLogo}>
                    <GlobalHeaderProfile popover={
                        <Popover
                            body={userAvatarMenu}
                            id="header-profile-popover-id"
                        />
                    }
                        userName={user.name }>
                    </GlobalHeaderProfile>
                    <GlobalHeaderSetup
                        dropdown={
                            <Dropdown
                                id="header-setup-dropdown-id"
                                options={globalHeaderOptions}
                                onSelect={(event) => selectGlobalItem(event) }
                            />
                        }
                    />
                </GlobalHeader>
                {matches && (<HorizontalNavigation {...menuParams} />)}

                {!matches && (<VertivalNavigation {...menuParams} />)}
            </IconSettings>
            
            )}
        
    </Fragment>);
}

export const HorizontalNavigation = ({ dropdownMercado, dropdownProdutos, parametrosMercado, dropdownFAQ, dropdownGiveaway }) => {
    const navigate = useNavigate();

    const navigateToPage = (event) => {
        const objectName = event.href.split('=')[1];
        navigate(event.href.replace('/object', `${objectName}`));
    }

    return (<GlobalNavigationBar style={{ paddingTop: '150px' }}>
        <GlobalNavigationBarRegion region="primary">
            <MediaObject className='slds-text-heading_small'
                body="Administração"
                verticalCenter
            />

        </GlobalNavigationBarRegion>
        <GlobalNavigationBarRegion region="secondary" navigation>
            <GlobalNavigationBarLink label="Contas" href={'/Account/list'} onClick={(event, href) =>
                navigateToPage(href)
            } />
            <GlobalNavigationBarDropdown
                assistiveText={{ icon: 'Open menu item submenu' }}
                label="Mercado"
                options={dropdownMercado}
                onSelect={(event) => navigateToPage(event)}
                align={'left'}
            />
            <GlobalNavigationBarDropdown
                assistiveText={{ icon: 'Open menu item submenu' }}
                label="Produtos"
                options={dropdownProdutos}
                onSelect={(event) => navigateToPage(event)}
                align={'left'}
            />
            <GlobalNavigationBarDropdown
                assistiveText={{ icon: 'Open menu item submenu' }}
                label="Parâmetros do mercado"
                options={parametrosMercado}
                onSelect={(event) => navigateToPage(event)}
                align={'left'}
            />
            <GlobalNavigationBarDropdown
                assistiveText={{ icon: 'Open menu item submenu' }}
                label="FAQ"
                options={dropdownFAQ}
                onSelect={(event) => navigateToPage(event)}
                align={'left'}
            />
            <GlobalNavigationBarDropdown
                assistiveText={{ icon: 'Open menu item submenu' }}
                label="Sorteio"
                options={dropdownGiveaway}
                onSelect={(event) => navigateToPage(event)}
                align={'left'}
            />
        </GlobalNavigationBarRegion>
    </GlobalNavigationBar>);
};

export const VertivalNavigation = ({ dropdownMercado, dropdownProdutos, parametrosMercado, dropdownFAQ, dropdownGiveaway }) => {    
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useState('');
    const [appLauncherOpen, setAppLauncherOpen] = useState(false);

    const categories = [
        {
            id: 'MenuAccount',
            label: 'Account',
            items: [{
                label: 'Account',
                value: '1',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/Account/list',
            }],
        },
        {
            id: 'MenuMercado',
            label: 'Mercado',
            items: dropdownMercado,
        },
        {
            id: 'MenuProdutos',
            label: 'Produtos',
            items: dropdownProdutos,
        },
        {
            id: 'MenuParametosMercado',
            label: 'Parâmetros do mercado',
            items: parametrosMercado,
        },
        {
            id: 'MenuFAQ',
            label: 'FAQ',
            items: dropdownFAQ, 
        },
        {
            id: 'MenuSorteios',
            label: 'Sorteios',
            items: dropdownGiveaway
        }
    ];

    const toggleAppLauncher = () => {
        setAppLauncherOpen(!appLauncherOpen);
    }

    const appLauncher = (<GlobalNavigationBar>
        <GlobalNavigationBarRegion region="primary">
            <AppLauncher id="app-launcher-trigger"
                triggerName="Menu"
                isOpen={appLauncherOpen}
                triggerOnClick={toggleAppLauncher}
                onClose={toggleAppLauncher}
            >
                <VerticalNavigation
                    id="verticalMainMenu"
                    categories={categories}
                    selectedId={selectedMenu}
                    onSelect={(event, data) => {
                        const objectName = data.item.href.split('=')[1];
                        navigate(data.item.href.replace('/object', `${objectName}`));
                        setSelectedMenu(data.item.id, { replace: true });
                        setAppLauncherOpen(false);
                    }}
                />
            </AppLauncher>
        </GlobalNavigationBarRegion>
    </GlobalNavigationBar>)

    return (<Fragment>
        {appLauncher}
    </Fragment>)
}