import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { PageHeader, TabsPanel, Tabs, Icon, Card, Button, IconSettings, PageHeaderControl, ButtonGroup} from '@salesforce/design-system-react';
import { useParams } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { EditFormModal } from './EditForm';
import { DeleteModal } from './DeleteModal';
import { LayoutContext } from '../Layout';
import { TableList } from './TableList';
import { Link } from 'react-router-dom';
import { OutputText } from './OutputText';
import { CustomAction } from './actions/CustomAction';

export const Detail = (props) => {
    const { object, recordId } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { notifyToast } = useContext(LayoutContext);
    const editFormModal = useRef(null);
    const deleteModal = useRef(null);
    const customActionModal = useRef(null);

    useEffect(() => {
        setLoading(true);
        getRecord();
    },[]);

    const getRecord = async () => {

        const token = await authService.getAccessToken();

        const tableListaParam = {
            objectName: object,
            id: recordId,
            mode: 'detail'
        };

        const params = new URLSearchParams(tableListaParam).toString();

        const response = await fetch('basicadmininstration/getRecord?' + params, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json;"
            },
            method: "GET"
        });

        const result = await response.json();

        if (!result.success) {

            notifyToast({
                title: 'Erro buscar registro',
                body: result.errors.join(' \n '),
                static: true,
                variant: 'error'
            })
        } else {
            result.lines.forEach((line) => {
                line.values.forEach((columnValue) => {
                    if (columnValue?.displayValue) {
                        columnValue[columnValue.fieldName + 'ValueCode'] = columnValue?.value?.toString();
                    }
                });
            });
        }

        setData(result);
        setLoading(false);
    }

    const onSuccess = () => {
        setLoading(true);
        getRecord();
    }

    const onSuccessDelete = () => {
        const redirectUrl = `${window.location.origin}/object/list?objectName=${object}`;
        window.location.replace(redirectUrl);
    }

    const handleAction = (action) => {
        if (action.standard) {
            if (action.apiName === 'Delete') {
                deleteModal.current.setModalBody(data.Name);
                deleteModal.current.setTarget(recordId, object);
                deleteModal.current.toggle();
            } else if (action.apiName === 'Update') {
                editFormModal.current.setTitle('Editar ' + object)
                editFormModal.current.setTarget(recordId, object);
                editFormModal.current.toggle();
            }
        } else {
            customActionModal.current.setTitle(action.name);
            customActionModal.current.setTarget(recordId, object, action);
            customActionModal.current.toggle();
        }
    }

    const headerActions = data?.actions?.filter(a => a.type === 'DetailLayout')
        .map(action => (<Button label={action.name} onClick={() => { handleAction(action) }} key={'act-' + action.name} />)) ?? [];

    const actions = () => (<Fragment>
        <PageHeaderControl>
            <ButtonGroup variant="list" id="button-group-page-header-actions">
                {headerActions }
            </ButtonGroup>
        </PageHeaderControl>
    </Fragment>);

    const headerFields = data?.headerFields?.map((field) => {

        if (data.lines.length > 0) {
            const line = data.lines[0];
            const fieldValue = line.values.find(a => a.fieldName === field.name);

            return {
                label: field.name,
                content: (
                    <OutputText
                        {...field}
                        {...line}
                        property={field.name}
                        item={fieldValue}
                        dontLinkName={true}>
                        {fieldValue?.displayValue ?? fieldValue?.value}
                    </OutputText>),
                truncate: true,
            };
        }

        return (<></>);
    });

    const fields = data?.fields?.map((field) => {

        if (data.lines.length > 0) {

            const line = data.lines[0];
            const fieldValue = line.values.find(a => a.fieldName === field.name);

            let inputType = (<div class="slds-form-element slds-border_bottom slds-p-bottom_small">
                <label class="slds-form-element__label">{field.label}</label>
                <div class="slds-form-element__control">
                    <OutputText
                        {...field}
                        {...line}
                        property={field.name}
                        item={fieldValue}
                        dontLinkName={true}>
                        {fieldValue?.displayValue ?? fieldValue?.value}
                    </OutputText>
                </div>
            </div>);
        
            return (<Fragment key={'edit-form-' + line.id + field.name}>
                <div className='slds-col slds-size_1-of-2 slds-p-horizontal_small'>
                    {inputType }
                </div>
            </Fragment>);
        }

        return (<></>)
    });

    let title = '';
    if (data?.lines && data.lines.length > 0 && data.lines[0].values) {
        title = data.lines[0].values?.find(a => a.fieldName === 'Name')?.value;
    }

    const relatedLists = data?.relatedObjects?.map((relatedObj, idx) => {
        const filters = [{
            fieldName: relatedObj.fieldName,
            filterCondition: 'equals',
            filterValue: recordId
        }];
        const url = `/${relatedObj.objectName}/list?${relatedObj.fieldName}=${recordId}`;
        
        return (<article class="slds-card slds-card_boundary" key={'rel' + idx} >
            <TableList mode='RelatedList'
                pageSize={10}
                hasMoreLines={false}
                objectName={relatedObj.objectName}
                fieldName={relatedObj.fieldName}
                filters={filters} />
            <footer className='slds-card__footer'>
                <Link className="slds-card__footer-action" to={url} reloadDocument={true}>Visualizar tudo</Link>
            </footer>   
        </article>);
    }) ?? [];

    return (<Fragment>
        <IconSettings iconPath="/assets/icons">
            <PageHeader
                details={headerFields}
                icon={
                    <Icon
                        assistiveText={{ label: 'User' }}
                        category="standard"
                        name="opportunity"
                    />
                }
                label={object }
                onRenderActions={actions}
                title={title}
                variant="record-home"
            />
            <EditFormModal key={'modal' + object} ref={editFormModal} onSuccess={() => onSuccess()}></EditFormModal>
            <DeleteModal key={'modalDelete' + object} ref={deleteModal} onSuccess={() => onSuccessDelete()}></DeleteModal>
            <CustomAction ref={customActionModal} onSuccess={() => onSuccess()} />
            <Card hasNoHeader={true} className='slds-m-top_medium' >
                <Tabs id="tabs-example-default">
                    <TabsPanel label="Detalhes">
                        <div className='slds-grid slds-wrap'>
                            {fields}
                        </div>
                    </TabsPanel>
                    <TabsPanel label="Relacionado">
                        <div className='slds-grid slds-wrap slds-m-around_medium'>
                            {relatedLists}
                        </div>
                    </TabsPanel>
                </Tabs>
            </Card>
        </IconSettings>
    </Fragment>);
}