import React, { Fragment, useContext, useImperativeHandle, forwardRef } from 'react';
import { LayoutContext } from '../../../Layout';

export const RestartAll = forwardRef((props, ref) => {

    const { api, notifyToast} = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return false;
        },
        onSave() {
            onSaveFunction();
        }
    }));

    const onSaveFunction = async () => {

        props.setIsLoading(true);

        api.doGET('bot/restart-all').then(result => {
            if (result.success) {
                notifyToast({
                    title: 'Bots reiniciados com sucesso',
                    static: false,
                    variant: 'success'
                });

                props.toggle();
            } else {
                notifyToast({
                    title: 'Erro ao solicitar reinicialização dos BOTS',
                    static: false,
                    variant: 'error'
                });
            }

            props.setIsLoading(false);
            props.callBack();
        });

    }

    return (<Fragment>
        <div className='slds-text-color_default'>
            Deseja realmente reinicializar todos os BOTS ?
        </div>
    </Fragment>);
});

export const Restart = forwardRef((props, ref) => {

    const { api, notifyToast } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
        hasNextStep() {
            return false;
        },
        onSave() {
            onSaveFunction();
        }
    }));

    const onSaveFunction = async () => {

        props.setIsLoading(true);

        api.doGET(`bot/restart?id=${props.id}`).then(result => {
            if (result.success) {
                notifyToast({
                    title: 'Bot reiniciado com sucesso',
                    static: false,
                    variant: 'success'
                });

                props.toggle();
            } else {
                notifyToast({
                    title: 'Erro ao solicitar reinicialização dos BOTS',
                    static: false,
                    variant: 'error'
                });
            }

            props.setIsLoading(false);
            props.callBack();
        });

    };

    return (<Fragment>
        <div className='slds-text-color_default'>
            Deseja realmente reinicializar o BOT?
        </div>
    </Fragment>);
});